<template>
  <div class="page list">
    <product3></product3>
    <product3></product3>
    <product3></product3>
  </div>
</template>

<script>
  import product3 from 'components/product3'
  
  export default {
    name: 'mycollections',
    data() {
      return {
        searchValue: '',
      }
    },
    components: {
      product3,
    },
    methods: {
      doa() {
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 24px;
  }

</style>
